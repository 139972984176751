import { composeRenderProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

export const focusRing = tv({
  base: 'outline-none forced-colors:outline-[Highlight]',
  variants: {
    isFocusVisible: {
      false: '',
      true: 'ring-2 ring-opacity-60 ring-dark lab:ring-opacity-100 lab:ring-1 lab:ring-offset-2 lab:ring-offset-neutral',
    }
  }
});

export function composeTailwindRenderProps<T>(className: string | ((v: T) => string) | undefined, tw: string): string | ((v: T) => string) {
  return composeRenderProps(className, (className) => twMerge(tw, className));
}

export type CnArgs = Array<string | undefined | null | CnArgs | Record<string, boolean>>;


/**
* merge classes into one string
* You can provide multiple arguments, each argument can be a string, array, object , null or undefined
* for objects the keys will be the classnames and the value is a boolean that indicates if the classname should be added or not(true for add)
* @example
* mergeClasses('a', {'b': true, 'c': false}, null, undefined, ['d']);
* // returns 'a b d'
*/
export function cn(...args: CnArgs): string {
  const classes = new Set();
  for (const arg of args) {
    if (!arg) continue;
    if (typeof arg === 'number') continue;
    // strings
    if (typeof arg === 'string') {
      classes.add(arg.trim());
    }
    // we don't repeat the implementation of mergeClasses as it would be the same. So we just call it recursively.
    if (Array.isArray(arg)) {
      classes.add(cn(...arg));
    }
    // check if it's an object
    if (typeof arg === 'object') {
      for (const [key, value] of Object.entries(arg)) {
        if (value === true) {
          classes.add(key.trim());
        }
      }
    }
  }
  return Array.from(classes).join(' ');
}