import { useContext } from 'react';
import { tv } from 'tailwind-variants';
import {
  type DialogProps as RACDialogProps,
  type ModalOverlayProps,
  Dialog as RACDialog,
  Modal,
  OverlayTriggerStateContext,
  composeRenderProps,
  ModalOverlay,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import { Button, type ButtonProps } from './button';
import { CloseModalSVG } from 'components/iconsSVG/close';
import { getDomainValue } from 'utils/domain';
import { LabCloseIcon } from 'components/camperlab/icons/close';

export const overlayStyles = tv({
  base: 'fixed inset-0 isolate z-[100] flex h-[--visual-viewport-height] w-full items-center justify-center bg-disabled/40',
  variants: {
    isEntering: {
      true: 'duration-200 ease-out animate-in fade-in',
    },
    isExiting: {
      true: 'duration-200 ease-in animate-out fade-out',
    },
  },
});

const modalStyles = tv({
  base: 'max-h-full max-w-full bg-neutral bg-clip-padding text-left align-middle shadow-2xl max-md:h-full max-md:w-full forced-colors:bg-[Canvas]',
  variants: {
    isEntering: {
      true: 'duration-200 ease-out animate-in zoom-in-105',
    },
    isExiting: {
      true: 'duration-200 ease-in animate-out zoom-out-95',
    },
  },
});

export type DialogRootProps = ModalOverlayProps;

export function DialogRoot({ className, isDismissable = true, ...props }: DialogRootProps) {
  return (
    <ModalOverlay {...props} isDismissable={isDismissable} className={overlayStyles}>
      <Modal
        {...props}
        className={composeRenderProps(className ?? 'md:max-w-screen-sm lg:max-w-screen-md', (className, renderProps) =>
          modalStyles({ ...renderProps, className }),
        )}
      />
    </ModalOverlay>
  );
}

export function Dialog(props: RACDialogProps) {
  return <RACDialog {...props} className={twMerge('relative max-h-[inherit] overflow-auto p-7 outline outline-0 [[data-placement]>&]:p-4', props.className)} />;
}

export function DialogX({ onClose, absolute = true }: { onClose?: () => void; absolute?: boolean }) {
  const state = useContext(OverlayTriggerStateContext);
  return (
    <Button
      variant="none"
      onPress={() => {
        onClose?.();
        state.close();
      }}
      className={`${absolute ? 'absolute right-0 top-0 z-10 m-0.5' : ''} flex items-center p-2`}
    >
      <span className="sr-only">{'Close'}</span>
      {getDomainValue({
        camper: <CloseModalSVG aria-hidden />,
        nnormal: <CloseModalSVG aria-hidden />,
        camperlab: <LabCloseIcon aria-hidden />,
      })}
    </Button>
  );
}

type DialogCloseProps = ButtonProps & {
  onClose?: () => void;
};

export function DialogClose({ onClose, ...rest }: DialogCloseProps) {
  const state = useContext(OverlayTriggerStateContext);
  return (
    <Button
      {...rest}
      onPress={() => {
        onClose?.();
        state.close();
      }}
    />
  );
}
