import { composeRenderProps, Button as RACButton, type ButtonProps as RACButtonProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';

import { focusRing } from './component-utils';
import { ProgressCircle } from './progress-circle';
import { getDomainValue } from 'utils/domain';

export type ButtonProps = RACButtonProps & {
  variant?: 'solid' | 'outline' | 'text' | 'none';
  color?: 'primary' | 'accent' | 'error';
  underline?: boolean;
  children: React.ReactNode;
};

// Ponemos el ! important en los colores de hover porque antd cambia el color de los links en hover
export const btnStyles = tv({
  extend: focusRing,
  base: 'relative flex items-center justify-center text-center transition disabled:cursor-not-allowed',
  variants: {
    variant: {
      solid: 'text-inverted disabled:text-copy lab:shadow-none lab:disabled:text-inverted border shadow-sm disabled:border-disabled disabled:bg-disabled',
      outline: 'disabled:border-neutral/10 lab:shadow-none border shadow-sm disabled:border-disabled disabled:text-disabled',
      text: 'bg-transparent disabled:text-disabled',
    },
    color: {
      primary: '',
      accent: '',
      error: '',
    },
    underline: {
      true: 'pressed:underline underline-offset-2 hover:underline',
    },
  },
  compoundVariants: [
    // order by color
    {
      variant: 'solid',
      color: 'primary',
      className:
        'border-dark bg-neutral-inverted text-inverted hover:bg-neutral hover:!text-copy pressed:bg-neutral pressed:!text-copy lab:hover:border-accent lab:hover:bg-accent lab:pressed:border-accent lab:pressed:bg-accent',
    },
    {
      variant: 'outline',
      color: 'primary',
      className:
        'border-dark text-copy hover:bg-neutral-inverted hover:!text-inverted pressed:bg-neutral-inverted lab:hover:border-accent lab:hover:bg-accent lab:hover:!text-copy lab:pressed:border-accent lab:pressed:bg-accent lab:pressed:!text-copy bg-transparent',
    },
    {
      variant: 'text',
      color: 'primary',
      className: 'text-copy hover:!text-copy lab:hover:!text-accent lab:pressed:!text-accent',
    },
    {
      variant: 'solid',
      color: 'accent',
      className: 'border-accent bg-accent text-inverted hover:bg-neutral hover:!text-accent pressed:bg-neutral',
    },
    {
      variant: 'outline',
      color: 'accent',
      className: 'border-accent bg-neutral text-accent hover:bg-accent hover:!text-inverted pressed:bg-accent pressed:text-inverted',
    },
    {
      variant: 'text',
      color: 'accent',
      className: 'text-accent hover:!text-accent',
    },
    {
      variant: 'solid',
      color: 'error',
      className: 'text-inverted hover:bg-neutral pressed:bg-neutral border-error bg-error hover:text-error',
    },
    {
      variant: 'outline',
      color: 'error',
      className: 'bg-neutral hover:!text-inverted pressed:bg-error border-error text-error hover:bg-error',
    },
    {
      variant: 'text',
      color: 'error',
      className: 'text-error hover:!text-error',
    },
  ],
  defaultVariants: {
    variant: 'solid',
    color: 'primary',
  },
});

export const noneBtnStyles = tv({
  extend: focusRing,
});

const camperClassName = 'h-10 px-4 text-sm';
const labDefaultClassName = 'h-6 px-6 text-sm font-bold uppercase';
export const btnBaseClassName = getDomainValue({ camper: camperClassName, nnormal: camperClassName, camperlab: labDefaultClassName });
/**
 * @example
 * ```tsx
 * <Button variant="solid" color="primary">Solid</Button>
 * <Button variant="outline" color="highlight">Outline</Button>
 * <Button variant="text" color="error">Text</Button>
 */
export function Button({ variant = 'solid', color, className, underline = false, children, ...rest }: ButtonProps) {
  const defaultClassName = {
    solid: className ?? btnBaseClassName,
    outline: className ?? btnBaseClassName,
    destructive: className ?? btnBaseClassName,
    text: className ?? btnBaseClassName,
    none: className,
  }[variant];

  return (
    <RACButton
      {...rest}
      className={
        variant === 'none' ?
          composeRenderProps(defaultClassName, (className, renderProps) => noneBtnStyles({ ...renderProps, className }))
        : composeRenderProps(defaultClassName, (className, renderProps) =>
            btnStyles({ ...renderProps, variant, color, className, underline: variant === 'text' && underline }),
          )
      }
    >
      {({ isPending }) => (
        <>
          <span className="inline-flex" style={isPending ? { opacity: 0 } : undefined}>
            {children}
          </span>
          {isPending ?
            <div className="absolute inset-0 flex items-center justify-center">
              <ProgressCircle aria-label="pending" />
            </div>
          : null}
        </>
      )}
    </RACButton>
  );
}
