import { useContext } from 'react';
import { tv } from 'tailwind-variants';
import {
  type DialogProps as RACDialogProps,
  type ModalOverlayProps,
  Dialog as RACDialog,
  Modal,
  OverlayTriggerStateContext,
  composeRenderProps,
  ModalOverlay,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import { Button, type ButtonProps } from './button';
import { CloseModalSVG } from 'components/iconsSVG/close';
import { LabCloseIcon } from 'components/camperlab/icons/close';

import { overlayStyles } from './dialog';
import { getDomainValue } from 'utils/domain';

const drawerStyles = tv({
  base: 'bg-neutral fixed bottom-0 right-0 top-0 h-full max-h-full min-w-72 bg-clip-padding text-left align-middle shadow-2xl max-sm:w-full forced-colors:bg-[Canvas]',
  variants: {
    isEntering: {
      true: 'animate-in slide-in-from-right duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out slide-out-to-right duration-200 ease-in',
    },
  },
});

export type DrawerRootProps = ModalOverlayProps;

export function DrawerRoot({ className, children, isDismissable = true, ...props }: DrawerRootProps) {
  return (
    <ModalOverlay data-overlay {...props} isDismissable={isDismissable} className={overlayStyles}>
      <Modal
        className={composeRenderProps(className ?? 'md:max-w-screen-sm lg:max-w-screen-md', (className, renderProps) =>
          drawerStyles({ ...renderProps, className }),
        )}
      >
        {children}
      </Modal>
    </ModalOverlay>
  );
}

export function Drawer(props: RACDialogProps) {
  return <RACDialog {...props} className={twMerge('relative h-svh max-h-[inherit] overflow-auto outline outline-0', props.className)} />;
}

export function DrawerX({ onClose, absolute = true }: { onClose?: () => void; absolute?: boolean }) {
  const state = useContext(OverlayTriggerStateContext);
  return (
    <Button
      variant="none"
      onPress={() => {
        onClose?.();
        state.close();
      }}
      className={`${absolute ? 'absolute right-0 top-0 m-0.5' : ''} lab:-ml-2.5 lab:-mt-2.5 flex size-7 items-center justify-center`}
    >
      <span className="sr-only">{'Close'}</span>
      {getDomainValue({ camper: <CloseModalSVG aria-hidden />, nnormal: <CloseModalSVG aria-hidden />, camperlab: <LabCloseIcon aria-hidden /> })}
    </Button>
  );
}

type DrawerCloseProps = ButtonProps & {
  onClose?: () => void;
};

export function DrawerClose({ onClose, ...rest }: DrawerCloseProps) {
  const state = useContext(OverlayTriggerStateContext);
  return (
    <Button
      {...rest}
      onPress={() => {
        onClose?.();
        state.close();
      }}
    />
  );
}
