import { composeRenderProps, Link as RACLink, type LinkProps as RACLinkProps } from 'react-aria-components';
import { btnBaseClassName, btnStyles, type ButtonProps } from './button';

export type LinkProps = RACLinkProps & {
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  underline?: boolean;
};

export function Link({ variant = 'text', color, underline = true, className, ...rest }: LinkProps) {
  const defaultClassName = {
    solid: className ?? btnBaseClassName,
    outline: className ?? btnBaseClassName,
    destructive: className ?? btnBaseClassName,
    text: className ?? btnBaseClassName,
    none: className,
  }[variant];

  return (
    <RACLink
      {...rest}
      className={
        variant === 'none' ? defaultClassName : (
          composeRenderProps(defaultClassName, (className, renderProps) =>
            btnStyles({ ...renderProps, variant, color, underline: variant === 'text' && underline, className }),
          )
        )
      }
    />
  );
}
