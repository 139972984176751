export function LabCloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" className="size-3.5" {...props}>
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".4"
        d="m4.2 10.5-.7-.7L6.3 7 3.5 4.2l.7-.7L7 6.3l2.8-2.8.7.7L7.7 7l2.8 2.8-.7.7L7 7.7l-2.8 2.8Z"
      />
    </svg>
  );
}
