import { useState, useEffect, useRef, useMemo } from 'react';
import { bool, string } from 'prop-types';
import { Modal, Spin } from 'antd';
import { DialogTrigger } from 'react-aria-components';

import RecommendedBag from 'components/recommendedBag';
import { formatCurrency, getCookie, COOKIES, eraseCookie, getFixedPrice, obtainVoucherPrices } from 'utils/helpers';
import { FAMILIES } from 'utils/constants/products';
import { TARGET_SIZE_LABEL_MARKETS } from 'utils/constants/sizes';
import { ProgressiveImg } from 'components/base';
import { getCart } from 'actions/products';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { parseBagEcommerceGTM, parseBagItemsEcommerceGA4 } from 'utils/gtmUtils';
import { LabCartIcon } from 'components/camperlab/icons/cart';

import { Drawer, DrawerRoot, DrawerX } from 'components/ui/drawer';
import { Link } from 'components/ui/link';

import { useUser } from 'context/user';
import { useI18n } from 'context/i18n';
import useDelayedState from 'hooks/useDelayedState';
import { useIsMounted, useLink, useLocale } from 'hooks';

import styles from './style.module.css';
import { sortAndOrderMiniBag } from 'utils/bag';
import { getCountryFromMarket } from 'utils/translations';
import { getDomainValue } from 'utils/domain';

import type { TBag, TBagItem } from 'types/bag';

function MiniCartItemCamperlab({ product, onDelete }) {
  const { profileData, t, contextFilters, activatedPrevouchers } = useI18n();
  const { locale, country: localeCountry } = useLocale();

  const country = getCountryFromMarket(localeCountry);

  const showPrice = product.parentId !== undefined ? false : true;
  let parsedTarget = 'M';
  if (country === 'US') {
    parsedTarget = 'US';
  } else if (['M', 'W'].includes(product.target)) {
    parsedTarget = product.target;
  }

  const useSizeEquivalence =
    product.size && product.sizeLabel && product.size !== product.sizeLabel && [FAMILIES.SHOES, FAMILIES.ACCESSORIES].includes(Number(product.family));

  const sizeLabel =
    useSizeEquivalence && !TARGET_SIZE_LABEL_MARKETS.includes(localeCountry) ?
      <span className={styles.size}>{`${product.sizeLabel} US / ${product.size} EU`}</span>
    : <span className={styles.size}>
        {getDomainValue({
          camperlab: `${t('bolsa', 'camperlab.size')} `,
        })}
        {product.camperLabPan === true ?
          'US'
        : product.sizeLabel || product.size ?
          country
        : ''}{' '}
        {TARGET_SIZE_LABEL_MARKETS.includes(localeCountry) && product.family && Number(product.family) === FAMILIES.SHOES ?
          `${product.gender ?? product.target} ${product.sizeLabel || product.size}`
        : product.sizeLabel || product.size}
      </span>;

  const fixedPrice = getFixedPrice(localeCountry, profileData, product?.price?.current);
  const voucherPriceInfo = obtainVoucherPrices({ ...product, prices: product.price }, t, locale, contextFilters, activatedPrevouchers, false, true);
  let info: Record<string, any> = {};
  const oldDiscount = product.price.discount;

  if (voucherPriceInfo) {
    info = voucherPriceInfo.info || {};

    if (!voucherPriceInfo?.message) {
      product.price.voucherDiscount = info.discount;

      const infoOverride = { ...info };

      delete infoOverride.discount;
      product.price = { ...product.price, ...infoOverride };
    }
  }
  const { price } = product;
  const { voucherType } = price;
  let { voucherDiscount } = price;

  if (voucherDiscount !== undefined && voucherDiscount < 0) {
    voucherDiscount *= -1;
  }

  const discountToDisplay =
    oldDiscount ?
      voucherType === 'original.price' ?
        voucherDiscount || oldDiscount
      : oldDiscount
    : voucherDiscount || oldDiscount;

  const voucherTypeToUseInLabel = oldDiscount !== undefined ? voucherType : 'original.price';
  let appliedLabel = t('listado.productos', `voucher.price.message.${voucherTypeToUseInLabel}.applied`);

  if (voucherType === 'final.price' && oldDiscount !== undefined) {
    appliedLabel = appliedLabel.replace('<TAG discount>', voucherDiscount);
  }

  const appliedLabelToDisplay = info?.appliedLabel || voucherType ? appliedLabel : null;

  const priceElement =
    showPrice ?
      <p className={styles.productInfoPrice}>
        <span data-fp={fixedPrice} data-ge-price="data-ge-price" data-ge-basket-productsaleprice="data-ge-basket-productsaleprice">
          {product.price.currentFormated || `${product.price.current} ${formatCurrency(product.price.currency)}`}
        </span>

        {discountToDisplay && discountToDisplay > 0 && (
          <span
            className={`${product.price.discountFromVoucher ? `${styles.itemDiscount} ${styles.discountByVoucher}` : styles.itemDiscount} green`}
          >{`-${discountToDisplay}%`}</span>
        )}

        {product.price.previous && product.price.previous > product.price.current && (
          <s data-ge-basket-productlistprice="data-ge-basket-productlistprice" className={styles.itemDiscount}>
            {product.price.previousFormated || `${product.price.previous} ${formatCurrency(product.price.currency)}`}
          </s>
        )}
      </p>
    : null;

  const deleteButton =
    onDelete ?
      <button className={styles.removeProduct} onClick={() => onDelete(product.id)}>
        {t('generico', 'boton.borrar')}
      </button>
    : null;

  return (
    <div className={styles.menuItem}>
      <a href={product.url}>
        <ProgressiveImg
          src={product.thumbnail}
          alt={`bag image for ${product.productId}`}
          /* le paso las otherProps directamente para que funcione en integra */
          /* @ts-ignore */
          iscamperone={false}
          className="min-h-[100%] max-w-[100%] object-cover"
        />
      </a>
      <div className={styles.productInfo}>
        <p className="!text-[16px] uppercase">{product.name}</p>
        {sizeLabel}
        {deleteButton}

        {showPrice && appliedLabelToDisplay ?
          <span className={`${styles.productInfoPrice} ${styles.discountByVoucher}`}>{appliedLabelToDisplay}</span>
        : null}
      </div>
      {priceElement}
    </div>
  );
}

export function MiniBagCamperlab({ isInSalePath }) {
  const i18nContext = useI18n();
  const {
    t,
    profileData,
    setBagLength,
    setBag,
    setBagHasMemberOnlyProduct,
    setRecommendedProductsForBag,
    promoLabel,
    setPromoLabel,
    promoLabelType,
    setPromoLabelType,
  } = i18nContext;
  const { bag }: { bag: TBag } = i18nContext;

  const { contextFilters, activatedPrevouchers } = useUser();
  const isMounted = useIsMounted();
  const { locale, country: market } = useLocale();
  const to = useLink();
  // const [ bag, setBag ] = useState(null);
  const [visible, setVisible] = useDelayedState(false);
  const [loading, setLoading] = useState(false);
  const { emptyBagCollections, config } = profileData;
  const bagRef = useRef(null);
  const timer: { current: ReturnType<typeof setTimeout> } = useRef();
  const previousBagLength = useRef(undefined);
  const initialLoad = useRef(true);

  const showFreeShipping = config?.show_free_shipping || false;
  const showGiftPurchase = config?.show_gift_purchase || false;
  const showProducts = bag && bag.length > 0;
  const orderedBag = useMemo(() => sortAndOrderMiniBag({ bag: bag ?? [], locale }), [bag, locale]);
  const total =
    orderedBag && orderedBag.length > 0 ?
      JSON.parse(JSON.stringify(orderedBag)).reduce((accumulator, current) => {
        if (current.parentId) return accumulator;
        const voucherPriceInfo = obtainVoucherPrices({ ...current, prices: current.price }, t, locale, contextFilters, activatedPrevouchers, false, true);
        let currentPrice = current.price.current;

        if (voucherPriceInfo) {
          if (!voucherPriceInfo?.message) {
            currentPrice = Math.round(voucherPriceInfo.info.current);
          }
        }
        return accumulator + currentPrice;
      }, 0)
    : 0;

  const { currency } = profileData;
  const onlyOneItem = orderedBag && orderedBag.length === 1;
  const sunneiProducts = orderedBag?.filter((item) => item.sunnei === true);
  const hasOnlyOneSunnei = sunneiProducts?.length === 1;

  const handleOnClickRemoveBtn = async (id) => {
    setLoading(true);
    const cookiesBagId = getCookie(COOKIES.BAG);
    const employeeId = getCookie(COOKIES.EMPLOYEE_ID) || '';
    const voucher = getCookie(COOKIES.BONDS) || '';
    const zipCode = getCookie(COOKIES.ZIP) || '';
    const itemIndex = bag.findIndex((item) => item.id === id);
    const tax = zipCode && zipCode !== '' ? { zipcode: zipCode } : {};

    let itemToDelete: TBagItem;
    // Filtramos la bolsa para quitar el producto y cualquier producto que pertenezca a ese pack
    const filteredNewBag: TBag = bag.reduce((acc, bagItem) => {
      if (bagItem.id === id || bagItem.parentId === id) {
        if (bagItem.id === id) itemToDelete = bagItem;
        return acc;
      }
      return [...acc, { code: bagItem.productId, ...bagItem }];
    }, []);
    // Aqui vemos si queda en la bolsa algun producto con el mismo productId que el que vamos a borrar y si no lo hay quitamos de los recomendados aquellos con su originalProductId igual al productId del producto que vamos a borrar
    const hasSomeOfTheSameID = filteredNewBag.some((item) => item.productId === itemToDelete?.productId);
    if (!hasSomeOfTheSameID) {
      setRecommendedProductsForBag((prev) =>
        prev.filter((recommendedItem) => recommendedItem.originalProductId !== itemToDelete?.productId).map((item, i) => ({ ...item, order: i })),
      );
    }

    try {
      let updatedPromoLabel = null;
      const result = await getCart({
        lang: locale,
        products: filteredNewBag,
        bondValue: voucher,
        tax,
        hashOrderId: cookiesBagId,
        employeeId,
      });
      if (result) {
        dataLayerHandleEvent({
          event: 'eventoEC',
          eventCat: 'ecommerce',
          eventAct: 'removeFromCart',
          eventLbl: itemToDelete?.productId,
          eventValue: itemToDelete?.price?.current,
          ecommerce: {
            currencyCode: profileData?.currencySap,
            remove: {
              products: parseBagEcommerceGTM([itemToDelete]),
            },
          },
        });
        dataLayerHandleEvent({
          event: 'remove_from_cart',
          ecommerce: {
            currency: profileData?.currencySap,
            value: itemToDelete?.price?.current,
            items: parseBagItemsEcommerceGA4([itemToDelete], itemIndex + 1),
          },
          cart: {
            items: parseBagItemsEcommerceGA4(filteredNewBag),
          },
        });
        let updatedPromoLabelType = 'progress';
        if (showFreeShipping) {
          updatedPromoLabel = result?.freeShippingLabel || null;
        }
        if (showGiftPurchase) {
          updatedPromoLabel = result?.gwplabel || result?.gwplabelObtained || result?.freeShippingLabel || null;
          if (result?.gwplabelObtained && result?.gwplabelObtained !== '') {
            updatedPromoLabel = result?.gwplabelObtained;
            updatedPromoLabelType = 'obtained';
          }
        }
        const newShipments = result.productShipmentCost.shipmentsCosts;
        const isEmptyBag = newShipments.length === 0 || result.productShipmentCost.products.length === 0;
        if (filteredNewBag.length === 0 || isEmptyBag) {
          eraseCookie(COOKIES.BAG);
          eraseCookie(COOKIES.BAG_DATE);
        }
        setBagLength(isEmptyBag ? 0 : filteredNewBag.length);
        const hasMemberOnlyProduct = isEmptyBag ? false : filteredNewBag.filter((product) => product.membersOnly === true).length > 0;
        setBagHasMemberOnlyProduct(hasMemberOnlyProduct);
        setBag(isEmptyBag ? [] : filteredNewBag);
        setPromoLabel(updatedPromoLabel);
        setPromoLabelType(updatedPromoLabelType);
        setLoading(false);
      }
    } catch (error) {
      // handle error
      setLoading(false);
      console.error(error);
      Modal.error({
        title: t('bolsa', 'error.borrar.producto.titulo', 'An error has occurred'),
        content: t('bolsa', 'error.borrar.producto.descripcion', 'We are sorry. The product could not be removed.'),
      });
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      /* @ts-ignore */
      window.showMiniBag = () => {
        setVisible(true);
      };
    }
  }, []);

  useEffect(() => {
    const showBagIfItemAdded = () => {
      if (bagRef.current?.offsetParent === null) return;
      if (initialLoad.current) return;
      if (previousBagLength.current === undefined) {
        return;
      }
      if (previousBagLength.current >= bag.length) {
        return;
      }
      setVisible(true);
      timer.current = setTimeout(() => {
        if (isMounted()) {
          setVisible(false);
        }
      }, 6000);
    };

    showBagIfItemAdded();
    if (bag) {
      initialLoad.current = false;
      previousBagLength.current = bag.length;
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [bag]);

  const handleClickMiniBagIcon = () => {
    setVisible((prev) => !prev);
    return;
  };

  // Si un usuario interactua con la minibolsa en movil quitar el timeout para que no se cierre.
  const handleInteraction = () => {
    clearTimeout(timer.current);
  };

  const camperlabIcon = (
    <button className="flex items-center font-primary uppercase" onClick={handleClickMiniBagIcon}>
      <>
        <LabCartIcon id="lab-cart-icon" className="flex xl:hidden" height={18} width={22} />
        <span id="lab-cart-text" className="hidden text-[0.75rem] xl:flex">
          {t('mi.bolsa', 'bag.widget')}
        </span>
      </>
      {bag && !isInSalePath && bag?.length > 0 ?
        <span id="lab-cart-count" className="ml-1 min-w-[2em] text-left md:flex">
          ( {bag.length} )
        </span>
      : ''}
    </button>
  );

  return (
    <nav ref={bagRef} className={styles.bagIcon}>
      {camperlabIcon}

      <DialogTrigger isOpen={visible && !isInSalePath} onOpenChange={handleClickMiniBagIcon}>
        <DrawerRoot className="w-full md:max-w-sm lg:max-w-sm">
          <Drawer>
            <div className="relative flex h-full max-h-screen flex-grow flex-col overflow-x-hidden" onPointerDown={handleInteraction}>
              <div className="sticky top-0 z-[2] mb-32 flex flex-shrink-0 items-center justify-between bg-[#fff] p-2.5">
                <div className="flex items-center md:relative md:top-[5px] md:order-2">
                  <DrawerX absolute={false} />
                </div>
                <p className={`${styles.menuItemTitle} mb-0 flex items-center text-xs font-bold uppercase md:relative md:top-[5px]`}>
                  {t('mi.bolsa', 'bag.widget')}
                </p>
              </div>

              <div className="h-full px-2">
                {!showProducts && (
                  <div>
                    <p className="mb-0 font-bold uppercase">{t('menu.derecha', 'derecha.bolsa.vacia')}</p>
                    <p className="font-bold">{t('menu.derecha', 'take.look.collection', '***Take a look at our collection.')}</p>
                    <div className="flex flex-col">
                      {emptyBagCollections?.map((item) => (
                        <a
                          key={`collectionButton-${item.title}`}
                          href={item.url}
                          className="mb-2 w-full bg-[#000] p-2.5 uppercase text-[#fff] hover:text-[#fff]"
                        >
                          {item.title}
                        </a>
                      ))}
                    </div>
                  </div>
                )}

                {showProducts && (
                  <div className={styles.spinWrapper}>
                    {/* @ts-ignore */}
                    <Spin spinning={loading}>
                      <ul className={styles.bagList}>
                        <div className={`${styles.itemsWrapper} ${onlyOneItem ? styles.noScroll : ''}`}>
                          {orderedBag.map((product, index) => {
                            return (
                              <li
                                data-ge-basket-cartitemid={product.productId}
                                key={`${product.productId}-${index}`}
                                className={`${styles.productItem} h-[12em]`}
                              >
                                <MiniCartItemCamperlab product={product} onDelete={handleOnClickRemoveBtn} />
                                {Array.isArray(product.additionalProducts) && product.additionalProducts.length > 0 ?
                                  <ul className={styles.additionalProducts}>
                                    {product.additionalProducts.map((additionalProduct, additionalIndex) => (
                                      <li key={`${additionalProduct.productId}-${additionalIndex}`} className={styles.productItem}>
                                        <MiniCartItemCamperlab product={additionalProduct} onDelete={() => handleOnClickRemoveBtn(product.id)} />
                                      </li>
                                    ))}
                                  </ul>
                                : null}
                              </li>
                            );
                          })}
                        </div>
                        <RecommendedBag />
                        <li className={styles.btnPurchase} key="gotobag" id="bag-purchase-btn">
                          <div className={styles.purchaseWrapper}>
                            {hasOnlyOneSunnei && <p className="error" dangerouslySetInnerHTML={{ __html: t('bolsa', 'completa.sunnei') }} />}
                            {promoLabel && <p className={promoLabelType === 'obtained' ? styles.promoLabelObtained : styles.promoLabel}>{promoLabel}</p>}
                            <p className={styles.totalPrice}>
                              <span>{'Total'}</span>
                              <span
                                data-fp={getFixedPrice(market, profileData, total)}
                                data-ge-basket-totals="data-ge-basket-totals"
                              >{`${total} ${currency}`}</span>
                            </p>
                            <p className={styles.taxes}>
                              {getDomainValue({
                                camper:
                                  market !== 'US' && market !== 'CA' ? <span>{t('mis.pedidos', 'impuestos.incluidos', 'All taxes included')}</span> : null,
                                camperlab: <span>{t('mis.pedidos', 'impuestos.incluidos', 'All taxes included')}</span>,
                              })}

                              {(
                                profileData.freeShipping &&
                                profileData.freeShipping === true &&
                                profileData.freeShippingOver &&
                                profileData.freeShippingOver < total
                              ) ?
                                <span className={styles.taxesPrices}>{t('mis.pedidos', 'free.shipping.included', 'Free Shipping')}</span>
                              : null}
                            </p>
                            <Link href={`/${locale}/bolsa`} variant="solid" className="justify-start px-[8px] py-[5px]">
                              {`${t('menu.derecha', 'derecha.comprar')} (${bag.length})`}
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </Spin>
                  </div>
                )}
              </div>
            </div>
          </Drawer>
        </DrawerRoot>
      </DialogTrigger>
    </nav>
  );
}

MiniBagCamperlab.displayName = 'MiniBagCamperlab';
